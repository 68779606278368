@font-face {
     font-family: 'Source Sans Pro';
     font-style: normal;
     font-weight: 300;
     src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
          url('../fonts/source-sans-pro/source-sans-pro-light.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/source-sans-pro/source-sans-pro-light.woff') format('woff'); /* Modern Browsers */
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
         url('../fonts/source-sans-pro/source-sans-pro-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/source-sans-pro/source-sans-pro-regular.woff') format('woff'); /* Modern Browsers */
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
         url('../fonts/source-sans-pro/source-sans-pro-semibold.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/source-sans-pro/source-sans-pro-semibold.woff') format('woff'); /* Modern Browsers */
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
         url('../fonts/source-sans-pro/source-sans-pro-bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/source-sans-pro/source-sans-pro-bold.woff') format('woff'); /* Modern Browsers */
  }
