$primary-color: #21145f;
$accent-color: #824cef;
$primaryButtonColor: $primary-color;
$color: #535759;
$links: #21145f;
$primaryButtonFocus: #331f93;
$primaryButtonHover: #ad8af5;
$whiteBackground: #ffffff;
$sideMenuDark:#012834;
$grayElementBackground: #ebebeb;
$worldpay-light-gray: #e4e3de;
$borderProperties: 1px solid #a6a6a6;
$doubleBorderProperties: 2px solid #A6A6A6;
$innerBorderProperties: 0.25px solid #bfbfbf;
$grayBorderProperties: 1px solid #dcdcdc;
$sectionSeparation: 1px solid #bdbdbd;
$darkGrayBorderProperties: 1px solid #535759;
$disabledButtonBackground: #535759;
$disabledCardFont: #CCCCCC;
$standardFontColor:#012834;
$standardFontSize: 1.4rem;
$alternateFont: #737373;
$checkboxBase: #CCC;
$borderColor: #A6A6A6;
$pageBackgroundColor: #faf9f9;
$inputFieldBackgroundColor: #fcfcfc;
$inputFieldFocusBorder: 1px solid $primary-color;
$primaryButtonTextColor: #ffffff;
$font: Arial;
$alternateGreen: #61b86b;
$videoCardText: #69197d;
$appCreated: #824CEF;
$appInProgress: #FF8B22;
$appHalted: #1AE2E2;
$appAccepted: #3EF7C7;
$appRejected: #C80000;
$red: #FD5C00;
$yellow: #FFC428;
$green: #61B86B;
$teal: #017867;
$blue: #4A90E2;
$worldpay-red: #ff0000;

// Status Variables //
$statusFontStroke: 0.3px #000000;
$grayStatusFont: #000000;
$grayStatusBubble: rgba(90, 90, 90, 0.3);
$yellowStatusFont: #FFB900;
$yellowStatusBubble: rgba(255, 185, 0, 0.3);
$blueStatusFont: #4A90E2;
$blueStatusBubble: rgba(74, 144, 226, 0.3);
$redStatusFont: #FD5C00;
$redStatusBubble: rgba(253, 92, 0, 0.3);
$darkRedStatusFont: #C80000;
$darkRedStatusBubble: rgba(200, 0, 0, 0.3);
$greenStatusFont: #61B86B;
$greenStatusBubble: rgba(97, 184, 107, 0.3);
$darkGreenStatusFont: #28582d;
$darkGreenStatusBubble: rgba(55, 123, 63, 0.3);
$statusPadding: 5px 12px;
$statusFontSize: 9px;
$statusBorderRadius: 10px;

$pageHeader: #333333;
