/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "app/staticcolors.scss";
@import "app/staticfont_size.scss";

$primary-color: #21145f;
$inputFieldBorderProperties: 1px solid #dfdfdf;
$inputFieldBackgroundColor: white;
$inputFieldFocusBorder: 1px solid $primary-color;


textarea:focus {
  border: $inputFieldFocusBorder;
}

ion-input,
textarea {
  border: $inputFieldBorderProperties;
  color: #012834 !important;
  border-radius: 4px;
  background-color: $inputFieldBackgroundColor;
  height: 32px;
  min-height: 32px;
  resize: vertical;
  width: 90%;
  background: none;
  input {
    color: #012834 !important;
    padding-bottom: 10px !important;
    height: 32px;
    margin: 0px !important;
    width: 100%;

    :disabled {
      opacity: 1;
    }
  }

  input[type="text"] {
    text-align: right;
    padding-right: 0.5rem;
    color: #012834 !important;
    height: 32px;
    width: 100%;
  }
  input[type="file"] {
    color: #012834 !important;
  }
  input[type="text"]:disabled {
    opacity: 0.7 !important;
  }
}


select {
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  width: 95%;
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  height: 34px;
  line-height: 42px;
  color: #012834;
  padding-left: 5px;
}

select option {
  font-family: Source Sans Pro, Arial, sans-serif;
  height: 34px;
  line-height: 42px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #012834;
}
ngb-datepicker {
  background-color: white;
  z-index: 9;
}

.primarybtn {
  height: 36px;
  width: 72px;
  border-radius: 24px;
  background-color: #44cf32;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.primarybtn:hover {
  height: 36px;
  width: 72px;
  border: 3px solid #44cf32;
  border-radius: 24px;
  background: none;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}

.primarybtn:active {
  height: 36px;
  width: 72px;
  border: 2px solid #44cf32;
  border-radius: 24px;
  background: none;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 0 2px #ffc845;
}

.primarybtn:disabled {
  height: 36px;
  width: 72px;
  border-radius: 24px;
  background-color: #d3d8d9;
  color: #b3bfc2;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.primarybtn:disabled:hover {
  height: 36px;
  width: 72px;
  border-radius: 24px;
  border: none;
  background-color: #d3d8d9;
  color: #b3bfc2;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.disabledButton {
  height: 36px;
  width: 70px;
  border-radius: 24px;
  background-color: #d3d8d9;
  color: #b3bfc2;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.secondarybtn {
  height: 36px;
  width: 72px;
  border: 3px solid #44cf32;
  border-radius: 24px;
  background: none;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}

.secondarybtn:hover {
  height: 36px;
  width: 72px;
  border-radius: 24px;
  background-color: #44cf32;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.secondarybtn:active {
  height: 36px;
  width: 72px;
  border: 2px solid #44cf32;
  border-radius: 24px;
  background: none;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 0 2px #ffc845;
}

.secondarybtn:disabled {
  height: 36px;
  width: 72px;
  border: 3px solid #b3bfc2;
  border-radius: 24px;
  background: none;
  color: #b3bfc2;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}

.secondarybtn:disabled:hover {
  height: 36px;
  width: 72px;
  border: 3px solid #b3bfc2;
  border-radius: 24px;
  background: none;
  color: #b3bfc2;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}

.search-text {
  width: 20%;
  border: 1px solid;
  padding: 0px;
  float: right;
  border-radius: 5%;
  @media (max-width: 920px) {
    width: 30%;
  }

}

.page-header {
  font-family: Source Sans Pro, Arial, sans-serif !important;
  font-size: 26px;
  color: #012834;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  // text-transform: uppercase;

  padding-left: 37px;

  @media (max-width: 280px) {
    font-size: 14px;
  }
}

.left-nav-lbl {
  margin-left: 65px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 48px;
  font-family: Source Sans Pro, Arial, sans-serif;
  color: $whiteBackground;
  @media (max-width: 2000px) {
    margin-left: 15px;
    font-size: 18px;
  }
}

.table-header {
  letter-spacing: 0;
  font-family: Titillium Web,Arial,sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  text-align: left;

  color: #012834;

  @media (max-width: 920px) {
    font-size: 13px;
  }
}

.table-data {
  font-family: $HeaderFontsRegular;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.67;
  color: #012834;
  letter-spacing: 0;
}

.details-label {
  font-family: $HeaderFontsRegular;
  color: #012834;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  float: left;
}
.retryImage {
  height: 12px;
  width: 17px;
  top: 12px;
  cursor: pointer;
}

.retrybutton {
  background: #44cf32;
  background-color: #44cf32;
  border-radius: 100px;
  width: 78px;
  height: 36px;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #012834;
}

.retrybutton:hover {
  background: none;
  border: 2px solid #44cf32;
  border-radius: 100px;
  width: 78px;
  height: 36px;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #012834;
}

.retrybutton:active {
  background: none;
  border: 2px solid #44cf32;
  background-color: none;
  border-radius: 100px;
  width: 78px;
  height: 36px;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #012834;
  box-shadow: 0 0 0 2px #ffc845;
}

.retrybutton:disabled {
  background-color: #d3d8d9;
  color: #b3bfc2;
  border-radius: 100px;
  width: 78px;
  height: 36px;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
}

.retrybutton:disabled:hover {
  background-color: #d3d8d9;
  color: #b3bfc2;
  background-color: none;
  border-radius: 100px;
  width: 78px;
  height: 36px;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  border: none;
}

.details-value {
  font-family: $HeaderFontsRegular;
  color: #012834;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 21px;
  float: left;
  position: relative;
  width: 100%;
  word-break: break-all;
}

ion-segment .ion-label-class {
  font-family: Source Sans Pro,Arial,sans-serif;
  color: #012834;
  text-transform: none;
  font-size: 14px;
  font-style: normal;
}

ion-segment-button {
  --indicator-color: #4bcd3e;
  --indicator-height: .375rem;
  --color-checked: white;
  width: 183px !important;
  color: #012834;
  font-weight: 700;
  font-family: 'Source Sans Pro', Arial, sans-serif;
}

ion-label {
  letter-spacing: 0;
  font-family: Source Sans Pro, Arial, sans-serif;
    font-size: 14px;
  text-align: left;
  color: #757575;
}

.no-top-border {
  border-bottom: 3px #012834 solid;
  margin-left: 32px;
  margin-right: 20px;
}

ion-searchbar {
  --ion-background-color: $whiteBackground;
}

ion-content {
  --ion-background-color: $whiteBackground;
}

.ion-input-custom {
  background-position-x: 95%;
  height: 40%;
  color: #012834;
  padding: 15px;
  background-color: #fcfcfc;
  text-align: left;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  width: 95%;
  border-radius: 4px;
  background: none;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  z-index: 0;
}
.validation-errors {
  margin-top: 10px;
}

.error-message {
  color: #ff1f3e;
  font-size: 12px;
  font-family: $HeaderFontsRegular;
  margin-top: -15px;
}
.select-button {
  background-position-x: 98%;
  height: 36px;
  color: #012834;
  background: #ffffff;
  border-radius: 4px;
  text-align: left;

  width: 236px;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  position: absolute;

  font-family: $HeaderFontsRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #012834;
  padding-left: 12px;

  @media (max-width: 768px) {
    padding-left: 5px;
  }
  @media (max-width: 760px) {
    padding-left: 5px;
  }
  @media (max-width: 360px) {
    padding-left: 5px;
    width: 245px;
  }
  @media (max-width: 414px) {
    padding-left: 5px;
    width: 244px;
  }
  @media (max-width: 411px) {
    padding-left: 5px;
    width: 245px;
  }
  @media (max-width: 320px) {
    padding-left: 5px;
    width: 204px;
  }
  @media (max-width: 280px) {
    padding-left: 5px;
    width: 172px;
  }
  img {
    float: right;
    transform: rotate(90deg);
  }
}

.input-group {
  width: 100%;
  display: block;
  height: 40px;
  position: relative;
}

.input-group-datepicker {
  width: 100%;
  display: block;
  height: 40px;
  position: relative;
}

.input-group-append {
  position: absolute;
  top: -10px;
  right: 9%;
}

.btn.btn-primary,
.btn.btn-primary:focus {
  background-color: #4bcd3e;
  border: 2px solid #4bcd3e;
  box-shadow: none;
  color: #012834;
  border-radius: 18px;
}

.alert-success {
  box-sizing: border-box;
  color: #012834;
  background-color: #e9f9e8;
  border: 2px solid #4bcd3e;
}
.alert-info {
  box-sizing: border-box;
  color: #012834;
  background-color: #e7f9fd;
  border: 2px solid #3bcff0;
}
.alert-warning {
  box-sizing: border-box;
  color: #012834;
  background-color: #fff1ec;
  border: 2px solid #fd8d62;
}

.alert-danger {
  box-sizing: border-box;
  color: #012834;
  background-color: #ffe4e8;
  border: 2px solid #ff1f3e;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alignColClass {
  padding-left: 37px;
}

.loader-class {
  // margin-left: 42em;
  text-align: center;
  margin-top: 2em;
}
.spanCol {
  margin-top: 12em;
  margin-bottom: 7em;
  color: #012834;
  font-family: $HeaderFontsRegular;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  position: relative;
  margin-left: -150px;
  word-wrap: normal;
}
.imgClass {
  width: 64px;
  margin-left: 5em;
  margin-bottom: 20px;
}

.colClass {
  background-color: #f5f5f5;
  margin-left: 40px !important;
  margin-right: 145px !important;
  height: 35em;
  margin-bottom: 15px;
}

body {
  background-color: #ffffff;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

ion-list {
  background-color: #012834 !important;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

a {
  color: #2f80ed;
  cursor: pointer;
}

.section::-webkit-scrollbar {
  width: 16px;
}

.section::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.section::-webkit-scrollbar-thumb {
  background-color: #d4aa70;
  border-radius: 100px;
}

/* Hover tooltips */
.field-tip {
  position: relative;
}
.field-tip .tip-content {
  position: absolute;
  width: 200px; 
  z-index: 1;
  top: 100%;
  left: 60%;
  margin-left: -120px;
  padding: 10px;
  color: #fff;
  background: #333;
  -webkit-box-shadow: 2px 2px 5px #aaa;
  -moz-box-shadow: 2px 2px 5px #aaa;
  box-shadow: 2px 2px 5px #aaa;
  opacity: 0;
  -webkit-transition: opacity 250ms ease-out;
  -moz-transition: opacity 250ms ease-out;
  -ms-transition: opacity 250ms ease-out;
  -o-transition: opacity 250ms ease-out;
  transition: opacity 250ms ease-out;
  border-radius: 5px;
}

.field-tip .tip-content:before {
  content: " "; /* Must have content to display */
  position: absolute;
}
.field-tip:hover .tip-content {
  right: -20px;
  opacity: 1;
}
.sub-header {
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: bold;
  padding-left: 2px;
  margin-top: 10px;
  .-title {
      font-weight: 900;
      margin: 0;
  }
  color:#012834;
}

.shimmer{
  background-image: linear-gradient(
    90deg,
    #EEEEEE 0%, #EEEEEE 40%,
    #DDDDDD 50%,  #DDDDDD 55%,
    #EEEEEE 65%, #EEEEEE 100%
  );
  background-size: 400%;
  animation: shimmer 600ms infinite;
}
@keyframes shimmer{
  from {background-position: 100% 100%;}
  to {background-position: 0 0;}
}

.shimmer-loading .ngx-shimmer {
  background-image: linear-gradient(to right,#EEEEEE 0,#DDDDDD 20%,#EEEEEE 40%,#EEEEEE 100%);
  animation: 1s linear infinite forwards shimmerEffect;
}



mat-dialog-container{
  width:300px !important;
} 


.merchant-hierarchy-dialog-container .mat-dialog-container {
  width: 100vw !important;
  max-width: 768px;
}

.custom-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}

/*This piece of CSS will disable the button for non-work - Start*/
.primarybtndisable {
  height: 36px;
  width: 72px;
  border-radius: 24px;
  background-color: #44cf32;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.primarybtndisable:hover {
  height: 36px;
  width: 72px;
  border: 3px solid #44cf32;
  border-radius: 24px;
  background: none;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}

.primarybtndisable:active {
  height: 36px;
  width: 72px;
  border: 2px solid #44cf32;
  border-radius: 24px;
  background: none;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 0 2px #ffc845;
}

.primarybtndisable:disabled {
  height: 36px;
  width: 72px;
  border-radius: 24px;
  background-color: #44cf32;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.primarybtndisable:disabled:hover {
  height: 36px;
  width: 72px;
  border-radius: 24px;
  border: none;
  background-color: #44cf32;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}
/*End*/
.dashboardHeader{
  padding-left: 5px;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
}

.convert-png-black{
  filter: invert(0%) sepia(71%) saturate(0%) hue-rotate(307deg) brightness(-20%) contrast(265%);
}

.convert-png-black:hover{
  filter: none !important;
}

.fis-icon{
  width: 6%;
  cursor: pointer;
}

.alert-button-inner.sc-ion-alert-md {
  justify-content: center;
}

.merchant-hierarchy-link {
  color: #2f80ed;
  cursor: pointer;
  top: 1px;
  position: relative;
}
.display-none {
  display: none;
}
.disabled-input {
  background-color: #f5f5f5;
  text-align: left; 
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  width: 95%;
}
.rmv-btn-enity {
  margin-top: 18px;
}

.custom-w-105, .custom-w-105:hover {
  width: 105px;
}
.float-right {
  float: right;
}

.entity-class .mat-dialog-container {
  width: 100vw !important;
  max-width: 768px !important;
}

.entity-type-style {
  width: 95%;
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  height: 32px;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: bold; 
  font-size: 13px;
}


.page-sub-header {
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-size: 18px;
  color: #012834;
  font-weight: bold;
  line-height: 55px;
  text-align: center;
  // text-transform: uppercase;

  padding-left: 37px;

  @media (max-width: 280px) {
    font-size: 12px;
  }
  @media (max-width: 920px) {
    font-size: 20px;
  }
}

.dashboardHeaderCount{
  padding-left: 5px;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #012834;
}

.viewAll{
  font-family: Source Sans Pro, Arial, sans-serif; 
  font-weight: 600; 
  cursor: pointer;
  font-size: 14px;
  margin-left: 9px;
  color: #012834;
}

.commonFontStyleModule{
  font-family: 'Source Sans Pro', Arial, sans-serif !important; 
  font-size: 14px !important;
  color: #012834 !important;
}

