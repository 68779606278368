@font-face {
  font-family: 'fisfont';
  font-style: normal;
  font-weight: normal;
  src:  url('../fonts/fisfont/fisfont-regular.eot?66nemn');
  src:  url('../fonts/fisfont/fisfont-regular.eot?66nemn#iefix') format('embedded-opentype'),
    url('../fonts/fisfont/fisfont-regular.ttf?66nemn') format('truetype'),
    url('../fonts/fisfont/fisfont-regular.woff?66nemn') format('woff'),
    url('../fonts/fisfont/fisfont-regular.svg?66nemn#fisfont') format('svg');
}

[class^='fis-icon-'], [class*=' fis-icon-'] {
  display: inline-block;
  font-family: 'fisfont';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
 	-moz-osx-font-smoothing: grayscale;
}

/* Reason for !important: class added by user meant to override existing font-size.*/
 .xsm-ic, .fis-icon-xs {
    font-size: 12px !important;
}

/* Reason for !important: class added by user meant to override existing font-size.*/
 .sm-ic, .fis-icon-sm {
    font-size: 18px !important;
}

/* Reason for !important: class added by user meant to override existing font-size.*/
 .med-ic, .fis-icon-md {
    font-size: 20px !important;
}

/* Reason for !important: class added by user meant to override existing font-size.*/
 .lrg-ic, .fis-icon-lg {
    font-size: 24px !important;
}

/* Reason for !important: class added by user meant to override existing font-size.*/
 .xlrg-ic, .fis-icon-xl {
    font-size: 32px !important;
}

.fis-icon-flip-horizontal {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}

.fis-icon-flip-vertical {
    transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
}

.fis-icon-rotate-90 {
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.fis-icon-rotate-180 {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.fis-icon-rotate-270 {
  transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
}
.ic-spin, .fis-icon-spin {
  -webkit-animation: fis-icon-spin 2s infinite linear;
  animation: fis-icon-spin 2s infinite linear;
}
.ic-pulse, .fis-icon-pulse {
  -webkit-animation: fis-icon-pulse 1s infinite steps(8);
  animation: fis-icon-pulse 1s infinite steps(8);
}

@-webkit-keyframes fis-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fis-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes fis-icon-pulse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fis-icon-pulse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/*
The naming scheme is for each word to be lowercase and seperated by a dash.
All other naming schemes are depracated and will be removed in a future release.
*/

.fis-icon-accounts:before {
  content: "\e900";
}
.fis-icon-action:before {
  content: "\e901";
}
.fis-icon-add:before {
  content: "\e95a";
}
.fis-icon-align-center:before {
  content: "\e97e";
}
.fis-icon-align-left:before {
  content: "\e97f";
}
.fis-icon-announcements:before {
  content: "\e98b";
}
.fis-icon-approval:before {
  content: "\e902";
}
.fis-icon-approximate:before {
  content: "\e9df";
}
.fis-icon-arrow:before {
  content: "\e903";
}
.fis-icon-arrow-down:before {
  content: "\e904";
}
.fis-icon-arrow-right-angle:before {
  content: "\e990";
}
.fis-icon-ascend:before {
  content: "\e961";
}
.fis-icon-asterisk:before {
  content: "\e9f6";
}
.fis-icon-attachment:before {
  content: "\e97d";
}
.fis-icon-back:before {
  content: "\e906";
}
.fis-icon-bank:before {
  content: "\e907";
}
.fis-icon-basket:before {
  content: "\e9d0";
}
.fis-icon-bank-check:before {
  content: "\e953";
}
.fis-icon-billpay:before {
  content: "\e908";
}
.fis-icon-blockquote:before {
  content: "\e980";
}
.fis-icon-bold:before {
  content: "\e981";
}
.fis-icon-book:before {
  content: "\e9a5";
}
.fis-icon-bookmark:before {
  content: "\e909";
}
.fis-icon-bookmark-add:before {
  content: "\e991";
}
.fis-icon-bookmark-remove:before {
  content: "\e992";
}
.fis-icon-brain:before {
  content: "\e9d7";
}
.fis-icon-bug:before {
  content: "\e9a1";
}
.fis-icon-building:before {
  content: "\e90a";
}
.fis-icon-bullet:before {
  content: "\e9a0";
}
.fis-icon-calculator:before {
  content: "\e979";
}
.fis-icon-calendar:before {
  content: "\e90b";
}
.fis-icon-camera:before {
  content: "\e90c";
}
.fis-icon-car:before {
  content: "\e97c";
}
.fis-icon-card:before {
  content: "\e90d";
}
.fis-icon-card-reader:before {
  content: "\e9eb";
}
.fis-icon-cash:before {
  content: "\e9cf";
}
.fis-icon-chart:before {
  content: "\e90e";
}
.fis-icon-chat:before {
  content: "\e90f";
}
.fis-icon-check:before {
  content: "\e95b";
}
.fis-icon-check-list:before {
  content: "\e9b8";
}
.fis-icon-chevron:before {
  content: "\e910";
}
.fis-icon-chevron-double:before {
  content: "\e958";
}
.fis-icon-chevron-skip:before {
  content: "\e9a6";
}
.fis-icon-clipboard:before {
  content: "\e911";
}
.fis-icon-clock:before {
  content: "\e9fc";
}
.fis-icon-close:before {
  content: "\e912";
}
.fis-icon-cloud:before {
  content: "\e9a7";
}
.fis-icon-cloud-download:before {
  content: "\e9ad";
}
.fis-icon-cloud-upload:before {
  content: "\e9ae";
}
.fis-icon-code:before {
  content: "\e9af";
}
.fis-icon-collateral:before {
  content: "\e9ee";
}
.fis-icon-compass:before {
  content: "\e9db";
}
.fis-icon-compress:before {
  content: "\e913";
}
.fis-icon-controls:before {
  content: "\e9d9";
}
.fis-icon-copy:before {
  content: "\e95e";
}
.fis-icon-cut:before {
  content: "\e960";
}
.fis-icon-cycle:before {
  content: "\e9fb";
}
.fis-icon-dashboard:before {
  content: "\e905";
}
.fis-icon-data:before {
  content: "\e950";
}
.fis-icon-database:before {
  content: "\e9a2";
}
.fis-icon-database-group:before {
  content: "\e9a8";
}
.fis-icon-delete:before {
  content: "\e914";
}
.fis-icon-delta:before {
  content: "\e9f2";
}
.fis-icon-density1:before {
  content: "\e9ba";
}
.fis-icon-density2:before {
  content: "\e9b9";
}
.fis-icon-density3:before {
  content: "\e9bb";
}
.fis-icon-descend:before {
  content: "\e95f";
}
.fis-icon-desktop:before {
  content: "\e96c";
}
.fis-icon-dimension:before {
  content: "\e962";
}
.fis-icon-dining:before {
  content: "\e997";
}
.fis-icon-direct-mail:before {
  content: "\e915";
}
.fis-icon-direction:before {
  content: "\e967";
}
.fis-icon-divide:before {
  content: "\e9f1";
}
.fis-icon-doctor:before {
  content: "\e94e";
}
.fis-icon-donut:before {
  content: "\e916";
}
.fis-icon-download:before {
  content: "\e917";
}
.fis-icon-drag:before {
  content: "\e9d3";
}
.fis-icon-drawer:before {
  content: "\e9d6";
}
.fis-icon-edit:before {
  content: "\e918";
}
.fis-icon-elbow:before {
  content: "\e9f0";
}
.fis-icon-email:before {
  content: "\e919";
}
.fis-icon-entertainment:before {
  content: "\e998";
}
.fis-icon-error:before {
  content: "\e91a";
}
.fis-icon-error-solid:before {
  content: "\e956";
}
.fis-icon-expand:before {
  content: "\e91b";
}
.fis-icon-export:before {
  content: "\e91c";
}
.fis-icon-eye:before {
  content: "\e91d";
}
.fis-icon-eye-hide:before {
  content: "\e963";
}
.fis-icon-eyedropper:before {
  content: "\e964";
}
.fis-icon-euro:before {
  content: "\20ac";
}
.fis-icon-faceprint:before {
  content: "\e9c5";
}
.fis-icon-favorite:before {
  content: "\e91e";
}
.fis-icon-favorite-half:before {
  content: "\e9e3";
}
.fis-icon-favorite-solid:before {
  content: "\e9e4";
}
.fis-icon-file:before {
  content: "\e91f";
}
.fis-icon-file-add:before {
  content: "\e98c";
}
.fis-icon-file-broken:before {
  content: "\e9de";
}
.fis-icon-filecheck:before {
  content: "\e920";
}
.fis-icon-file-landscape:before {
  content: "\e9ef";
}
.fis-icon-file-remove:before {
  content: "\e98d";
}
.fis-icon-file-tree:before {
  content: "\e97a";
}
.fis-icon-filter:before {
  content: "\e952";
}
.fis-icon-filter-remove:before {
  content: "\e9ed";
}
.fis-icon-fingerprint:before {
  content: "\e921";
}
.fis-icon-flag:before {
  content: "\e951";
}
.fis-icon-flame:before {
  content: "\e9d2";
}
.fis-icon-flask:before {
  content: "\e9e9";
}
.fis-icon-flow:before {
  content: "\e9e8";
}
.fis-icon-folder:before {
  content: "\e922";
}
.fis-icon-folder-new:before {
  content: "\e9fd";
}
.fis-icon-folder-open:before {
  content: "\e96d";
}
.fis-icon-folder-zip:before {
  content: "\ea01";
}
.fis-icon-format-apply:before {
  content: "\e982";
}
.fis-icon-format-remove:before {
  content: "\e983";
}
.fis-icon-gavel:before {
  content: "\e9e7";
}
.fis-icon-graph:before {
  content: "\e9fe";
}
.fis-icon-group:before {
  content: "\e923";
}
.fis-icon-headset:before {
  content: "\e999";
}
.fis-icon-heart:before {
  content: "\e924";
}
.fis-icon-heart-remove:before {
  content: "\e9b0";
}
.fis-icon-help:before {
  content: "\e925";
}
.fis-icon-help-solid:before {
  content: "\e926";
}
.fis-icon-hierarchy:before {
  content: "\e9cc";
}
.fis-icon-history:before {
  content: "\e9fa";
}
.fis-icon-home:before {
  content: "\e927";
}
.fis-icon-identification:before {
  content: "\e9a3";
}
.fis-icon-image:before {
  content: "\e9b1";
}
.fis-icon-incognito:before {
  content: "\e9bf";
}
.fis-icon-indent:before {
  content: "\e984";
}
.fis-icon-infinity:before {
  content: "\e9c1";
}
.fis-icon-information:before {
  content: "\e928";
}
.fis-icon-information-solid:before {
  content: "\e929";
}
.fis-icon-in-progress:before {
  content: "\e9ea";
}
.fis-icon-insecure:before {
  content: "\e92a";
}
.fis-icon-instructions:before {
  content: "\e92b";
}
.fis-icon-italic:before {
  content: "\e985";
}
.fis-icon-justify:before {
  content: "\e98f";
}
.fis-icon-keyboard:before {
  content: "\e97b";
}
.fis-icon-keypad:before {
  content: "\e9d8";
}
.fis-icon-laptop:before {
  content: "\e9d4";
}
.fis-icon-layout1:before {
  content: "\e9b3";
}
.fis-icon-layout2:before {
  content: "\e9b2";
}
.fis-icon-layout3:before {
  content: "\e9b4";
}
.fis-icon-leaf:before {
  content: "\e9be";
}
.fis-icon-ledger:before {
  content: "\e92c";
}
.fis-icon-legend:before {
  content: "\e9dc";
}
.fis-icon-lend:before {
  content: '\e9f8';
}
.fis-icon-light:before {
  content: "\e99a";
}
.fis-icon-lightning:before {
  content: "\e9c8";
}
.fis-icon-light-remove:before {
  content: "\e99b";
}
.fis-icon-link:before {
  content: "\e993";
}
.fis-icon-link-remove:before {
  content: "\e9c2";
}
.fis-icon-link-web:before {
  content: "\e92d";
}
.fis-icon-loan:before {
  content: "\e92e";
}
.fis-icon-locations:before {
  content: "\e92f";
}
.fis-icon-logout:before {
  content: "\e98e";
}
.fis-icon-max:before {
  content: "\e930";
}
.fis-icon-medical:before {
  content: "\e94f";
}
.fis-icon-meeting:before {
  content: "\e9f5";
}
.fis-icon-menu:before {
  content: "\e931";
}
.fis-icon-menu-drop:before {
  content: "\e932";
}
.fis-icon-menu-list:before {
  content: "\e933";
}
.fis-icon-merge:before {
  content: "\e965";
}
.fis-icon-microphone:before {
  content: "\e96e";
}
.fis-icon-microphone-mute:before {
  content: "\e966";
}
.fis-icon-min:before {
  content: "\e934";
}
.fis-icon-moneybag:before {
  content: "\e9ff";
}
.fis-icon-news:before {
  content: "\e955";
}
.fis-icon-note:before {
  content: "\e9ce";
}
.fis-icon-notification:before {
  content: "\e935";
}
.fis-icon-notification-off:before {
  content: "\e994";
}
.fis-icon-notification-panel:before {
  content: "\e936";
}
.fis-icon-offers:before {
  content: "\e937";
}
.fis-icon-ordered-list:before {
  content: "\e986";
}
.fis-icon-outdent:before {
  content: "\e987";
}
.fis-icon-package:before {
  content: "\e9c9";
}
.fis-icon-password:before {
  content: "\e988";
}
.fis-icon-paste:before {
  content: "\e9e2";
}
.fis-icon-pause:before {
  content: "\e96f";
}
.fis-icon-person:before {
  content: "\e938";
}
.fis-icon-phone:before {
  content: "\e939";
}
.fis-icon-piggy-bank:before {
  content: "\e9f4";
}
.fis-icon-pin:before {
  content: "\e93a";
}
.fis-icon-pin-remove:before {
  content: "\e996";
}
.fis-icon-play:before {
  content: "\e970";
}
.fis-icon-plugin:before {
  content: "\e9a9";
}
.fis-icon-plugin-execute:before {
  content: "\e9aa";
}
.fis-icon-popout:before {
  content: "\e93b";
}
.fis-icon-posak:before {
  content: "\e93c";
}
.fis-icon-posak-solid:before {
  content: "\e9ec";
}
.fis-icon-presentation:before {
  content: "\e9dd";
}
.fis-icon-preview:before {
  content: "\e9b5";
}
.fis-icon-print:before {
  content: "\e93d";
}
.fis-icon-prohibit:before {
  content: "\e9f3";
}
.fis-icon-qr-code:before {
  content: "\e9a4";
}
.fis-icon-record:before {
  content: "\e9c3";
}
.fis-icon-recurring:before {
  content: "\e93e";
}
.fis-icon-refresh:before {
  content: "\e93f";
}
.fis-icon-relationship:before {
  content: "\e9cd";
}
.fis-icon-registration:before {
  content: "\e954";
}
.fis-icon-reorder:before {
  content: "\e968";
}
.fis-icon-reply-all:before {
  content: "\e9f7";
}
.fis-icon-reports:before {
  content: "\e940";
}
.fis-icon-rewards:before {
  content: "\e99c";
}
.fis-icon-ribbon:before {
  content: "\e978";
}
.fis-icon-robot:before {
  content: "\e9c6";
}
.fis-icon-rolodex:before {
  content: "\e95d";
}
.fis-icon-save:before {
  content: "\e971";
}
.fis-icon-scale:before {
  content: "\e9c0";
}
.fis-icon-scan:before {
  content: "\e941";
}
.fis-icon-scrub:before {
  content: "\e972";
}
.fis-icon-search:before {
  content: "\e942";
}
.fis-icon-security:before {
  content: "\e943";
}
.fis-icon-send:before {
  content: "\e9c4";
}
.fis-icon-service:before {
  content: "\e995";
}
.fis-icon-settings:before {
  content: "\e944";
}
.fis-icon-share:before {
  content: "\e9c7";
}
.fis-icon-shield:before {
  content: "\e99f";
}
.fis-icon-shop:before {
  content: "\e9d1";
}
.fis-icon-shopping:before {
  content: "\e99d";
}
.fis-icon-shopping-cart:before {
  content: "\e957";
}
.fis-icon-skip:before {
  content: "\e973";
}
.fis-icon-sort-low-high:before {
  content: "\e9e1";
}
.fis-icon-sort-high-low:before {
  content: "\e9e0";
}
.fis-icon-split:before {
  content: "\e969";
}
.fis-icon-stop:before {
  content: "\e974";
}
.fis-icon-strategy:before {
  content: "\e9ab";
}
.fis-icon-strikethrough:before {
  content: "\e989";
}
.fis-icon-subtract:before {
  content: "\e95c";
}
.fis-icon-suggestions:before {
  content: "\e9da";
}
.fis-icon-suitcase:before {
  content: "\e945";
}
.fis-icon-table:before {
  content: "\e946";
}
.fis-icon-tag:before {
  content: "\e9bc";
}
.fis-icon-target:before {
  content: "\e947";
}
.fis-icon-telephone:before {
  content: "\e959";
}
.fis-icon-thumb-down:before {
  content: "\e9b6";
}
.fis-icon-thumb-up:before {
  content: "\e9b7";
}
.fis-icon-time:before {
  content: "\e948";
}
.fis-icon-transaction:before {
  content: "\e9bd";
}
.fis-icon-transaction-remove:before {
  content: "\e9e6";
}
.fis-icon-transfer:before {
  content: "\e949";
}
.fis-icon-translate:before {
  content: "\e9e5";
}
.fis-icon-trash:before {
  content: "\e94a";
}
.fis-icon-trophy:before {
  content: "\e9d5";
}
.fis-icon-underline:before {
  content: "\e98a";
}
.fis-icon-usd:before {
  content: "\0024";
}
.fis-icon-vault:before {
  content: "\e9ac";
}
.fis-icon-vertical-ellipsis:before {
  content: "\e94b";
}
.fis-icon-video:before {
  content: "\e94c";
}
.fis-icon-volume-down:before {
  content: "\e975";
}
.fis-icon-volume-mute:before {
  content: "\e976";
}
.fis-icon-volume-up:before {
  content: "\e977";
}
.fis-icon-waffle:before {
  content: "\e9f9";
}
.fis-icon-waiver:before {
  content: "\e94d";
}
.fis-icon-wallet:before {
  content: "\e99e";
}
.fis-icon-wand:before {
  content: "\ea02";
}
.fis-icon-wayfind:before {
  content: "\ea00";
}
.fis-icon-wifi:before {
  content: "\e9ca";
}
.fis-icon-world:before {
  content: "\e9cb";
}
.fis-icon-zoom-in:before {
  content: "\e96a";
}
.fis-icon-zoom-out:before {
  content: "\e96b";
}
.fis-icon-yen:before {
  content: "\00a5";
}

.fis-icon-usd:before, .fis-icon-euro:before , .fis-icon-yen:before {
  font-family: Roboto, Arial, sans-serif !important;
}
